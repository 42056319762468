import PropTypes from "prop-types"

import SplitBackground from "../atoms/split_background"
import Box from "@mui/material/Box"

const styles = {
  container: (theme) => ({
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("desktop")]: {
      marginTop: "80px",
      alignItems: "center",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.down("desktop")]: {
      marginTop: "16px",
      width: "94%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    "& a": {
      color: "#5698e4",
      cursor: "pointer",
      textDecoration: "none",
    },
  }),
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    backgroundColor: "white",
    boxShadow: "0 3px 3px rgba(0,0,0,.15)",
    borderRadius: "6px",
  },
  footer: {
    textAlign: "center",
    alignSelf: "center",
    color: "rgba(0,0,0,.4)",
    margin: "20px 0 20px",
  },
}

const WhiteCard = ({ children, footer, sx = {} }) => {
  return (
    <>
      <SplitBackground topColor="lightBlue" />
      <Box sx={[styles.container, sx]}>
        <Box sx={styles.card}>{children}</Box>
        <Box sx={styles.footer}>{footer}</Box>
      </Box>
    </>
  )
}

WhiteCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  footer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  sx: PropTypes.object,
}

export default WhiteCard
