import Fade from "@mui/material/Fade"
import CircularProgress from "@mui/material/CircularProgress"
import PropTypes from "prop-types"

const Loading = ({ inAction = true, style, delay = "200ms" }) => {
  const styles = {
    container: {
      position: "absolute",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
      zIndex: 1,
    },
    fade: {
      transitionDelay: inAction ? delay : "0ms",
    },
  }

  return (
    <div
      style={{
        ...styles.container,
        ...(style || {}),
      }}
    >
      <Fade in={inAction} unmountOnExit style={styles.fade}>
        <CircularProgress />
      </Fade>
    </div>
  )
}
Loading.propTypes = {
  inAction: PropTypes.bool,
  style: PropTypes.object,
  delay: PropTypes.string,
}

export default Loading
