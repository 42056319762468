import queryString from "query-string"

export const buildContinueWithDestination = () => {
  const destination = `${window.location.pathname}${window.location.search}`
  return `/login/?continueWith=${encodeURIComponent(destination)}`
}

export const getContinueWith = () => {
  const { continueWith } = queryString.parse(window.location.search)
  return continueWith
}

export const buildContinueWithFromSearch = () => {
  const continueWith = getContinueWith()
  return continueWith ? `continueWith=${encodeURIComponent(continueWith)}` : ""
}
