import axios from "axios"
import { captureException } from "@sentry/nextjs";

import { createAuth } from "./auth"
import { API_ROOT_URL } from "../requests"
import { store } from "../lib/store"

export const LOGIN = "LOGIN"

const onLoginSucceeded = ({ token, user }) => {
  localStorage.setItem("last_action", new Date().getTime())
  localStorage.setItem("login_date", new Date().toDateString())
  store.dispatch(createAuth(user, token))
}

const onLoginFailed = (err, failedCallback) => {
  if (err.response && err.response.data) {
    failedCallback(err.response.status)
  } else {
    captureException(err)
    failedCallback("")
  }
}

export function initiateLogin(phone, isSendAgain, successCallback, failedCallback) {
  const params = { phone, isSendAgain }
  axios
    .post(`${API_ROOT_URL}/public/login/initiate`, params)
    .then(({ data: identifier }) => successCallback(identifier))
    .catch((err) => {
      onLoginFailed(err, failedCallback)
    })
}

export const completeLogin = async (identifier, oneTimePassword, loginCallback, failedCallback) => {
  return verifyOtpAndFetchParticipant(
    "public/login/complete",
    identifier,
    oneTimePassword,
    loginCallback,
    failedCallback,
  )
}

export const completeSignup = async (identifier, oneTimePassword, loginCallback, failedCallback) => {
  return verifyOtpAndFetchParticipant(
    "public/signup/complete",
    identifier,
    oneTimePassword,
    loginCallback,
    failedCallback
  )
}

const verifyOtpAndFetchParticipant = async (action, identifier, oneTimePassword, loginCallback, failedCallback) => {
  const onError = (err) => onLoginFailed(err, failedCallback)
  const data = { oneTimePassword, ...identifier }
  axios
    .post(`${API_ROOT_URL}/${action}`, data)
    .then(({ data: token }) => {
      return axios
        .get(`${API_ROOT_URL}/protected/participant`, { headers: { Authorization: token } })
        .then(({ data: user }) => {
          onLoginSucceeded({ token, user })
          loginCallback()
        })
    })
    .catch((err) => {
      onLoginFailed(err, failedCallback)
    })
}
