import React from "react"
import PropTypes from "prop-types"
import colors from "../utils/colors"

const backgroundColors = {
  green: colors.brandLightGreen,
  lightBlue: colors.brandLightBlue,
  lightRed: colors.statesErrorTertiary,
}

const SplitBackground = ({ splitAt = "210px", topColor }) => {
  const styles = {
    splitBackground: {
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: -500,
      height: splitAt,
      backgroundColor: backgroundColors[topColor] || topColor,
    },
  }

  return <div style={styles.splitBackground} />
}

SplitBackground.propTypes = {
  splitAt: PropTypes.string,
  topColor: PropTypes.string.isRequired,
}

export default SplitBackground
