import PropTypes from "prop-types"
import TextField from "@mui/material/TextField"

const styles = {
  textFiled: { width: "100%", maxWidth: "30rem" },
}

const FinalFormTextField = ({
  input: { name, onChange, value, ...restInput },
  meta,
  style = {},
  type = null,
  placeholder = null,
  label = null,
  isMultiline = false,
  inputProps = null,
  InputLabelProps = {},
  helperText = " ",
  FormHelperTextProps = {},
  ...restProps
}) => {
  const hasError = !!(meta.error && meta.touched)
  const isRtl = true
  return (
    <TextField
      sx={[styles.textFiled, style]}
      placeholder={placeholder}
      label={label}
      type={type}
      name={name}
      helperText={hasError ? meta.error : helperText}
      FormHelperTextProps={{
        sx: {
          ...FormHelperTextProps?.style,
          marginTop: "8px",
          lineHeight: "1em",
        },
      }}
      error={hasError}
      InputProps={{ ...restInput, ...inputProps }}
      onChange={onChange}
      value={value}
      multiline={isMultiline}
      InputLabelProps={InputLabelProps}
      variant="standard"
      {...restProps}
    />
  )
}

FinalFormTextField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isMultiline: PropTypes.bool,
  style: PropTypes.object,
  inputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  helperText: PropTypes.string,
  FormHelperTextProps: PropTypes.object,
}

export default FinalFormTextField
