import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useRouter } from "next/navigation"

import { getContinueWith } from "../utils/url"

const withAnonymousRoute = (Component) => {
  return (props) => {
    const auth = useSelector((state) => state.auth)
    const router = useRouter()
    const to = getContinueWith() || "/home/"

    useEffect(() => {
      if (auth) {
        console.log("you are in an anonymous route with user. we forward you to: ", window.location.href, to)
        router.replace(to)
      }
    }, [auth, router, to])

    if (auth) {
      console.error("user with auth in anonymous routes", window.location.href, to)
      return null
    }

    return <Component {...props} />
  }
}

export default withAnonymousRoute
