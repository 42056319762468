import { forwardRef } from "react"
import PropTypes from "prop-types"
import Button from "@mui/material/Button"

const style = {
  borderRadius: 50,
  minWidth: "7rem",
  whiteSpace: "nowrap",
}

const generateColorStyle = (color) => {
  return (
    color && {
      color: color,
      borderColor: color,
    }
  )
}

const PhenoButton = forwardRef(({ children, color, sx = {}, ...props }, ref) => {
  return (
    <Button sx={[style, generateColorStyle(color), sx]} variant="contained" color="primary" ref={ref} {...props}>
      {children}
    </Button>
  )
})

PhenoButton.displayName = "PhenoButton"

PhenoButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  color: PropTypes.string,
  sx: PropTypes.object,
}

export default PhenoButton
